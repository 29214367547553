/* eslint import/prefer-default-export: 0 */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
import ReactDOM from 'react-dom';
import { navigate } from 'gatsby';
import { redirectPathMap } from './src/constants';

import './src/pages/index.css';
import './src/styles/global.css';
import 'prismjs/themes/prism.css';
import "remark-admonitions/styles/classic.css";

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};

export const onInitialClientRender = () => {
    const pathnameNoTrailingSlash = window.location.pathname.replace(/\/$/, '');
    if (redirectPathMap[pathnameNoTrailingSlash]) {
        document.querySelector('html').setAttribute('style', 'visibility: hidden');
        navigate(redirectPathMap[pathnameNoTrailingSlash]);
        const startTime = Date.now();
        const waitForSuccess = setInterval(() => {
            if (!document.querySelector('h1').textContent.includes("404 page")) {
                document.querySelector('html').removeAttribute('style');
                clearInterval(waitForSuccess);
            } else if ((Date.now() - startTime) > 5000) {
                clearInterval(waitForSuccess);
                navigate('/404/');
            }
        }, 100);

    } else if (window.location.pathname === '/') {
        navigate('/develop-overview');
    }
};
