const generalRedirects = {
    '/getting-started': '/develop-overview',
};

const webIntegRedirects = {
    '/websdk': '/web-integration',
    '/websdk/sitemap': '/web-integration/sitemap',
    '/websdk/validate-beacon-deployment': '/web-integration/sitemap/validate-beacon-deployment',
    '/websdk/site-map-getting-started': '/web-integration/sitemap/site-map-getting-started',
    '/websdk/sitemap-event-validation': '/web-integration/sitemap/sitemap-event-validation',
    '/websdk/contentzones': '/web-integration/sitemap/contentzones',
    '/websdk/pagetypes': '/web-integration/sitemap/pagetypes',
    '/websdk/item-actions': '/web-integration/sitemap/item-actions',
    '/websdk/site-map-user-account-attributes': '/web-integration/sitemap/site-map-user-account-attributes',
    '/websdk/sitemap-implementation-notes': '/web-integration/sitemap/sitemap-implementation-notes',
    '/websdk/examples': '/web-integration/sitemap/examples',
    '/websdk/examples/financial-services': '/web-integration/sitemap/examples/financial-services',
    '/websdk/examples/ecommerce': '/web-integration/sitemap/examples/ecommerce',
    '/websdk/examples/b2b': '/web-integration/sitemap/examples/b2b',
    '/websdk/identity': '/web-integration/user-identity-mapping',
    '/websdk/multisite-strategy': '/web-integration/sitemap/multisite-strategy',
    '/websdk/site-impact-policy': '/web-integration/site-impact-policy',
};

const eventApiRedirects = {
    '/api': '/event-api',
    '/api/event': '/event-api/event-api-specifications',
    '/api/event-api-response': '/event-api/event-api-response',
};

const mobileRedirects = {
    '/native-mobile-sdks': '/mobile-integration',
};

const campaignDevRedirects = {
    '/campaigns': '/campaign-development',
    '/campaigns/campaign-debugger': '/campaign-development/web-campaign-debugger',
    '/templates': '/campaign-development/web-templates',
    '/templates/javascript': '/campaign-development/web-templates/web-template-client-javascript',
    '/templates/typescript': '/campaign-development/web-templates/web-template-server-typescript',
    '/templates/template-building-checklist': '/campaign-development/web-templates/web-template-building-checklist',
    '/templates/campaign-stats': '/campaign-development/web-templates/web-campaign-stats',
    '/templates/template-building-checklist/campaign-stats': '/campaign-development/web-templates/web-campaign-stats',
    '/templates/display-utilities': '/campaign-development/web-templates/web-display-utilities',
    '/templates/template-building-checklist/display-utilities': '/campaign-development/web-templates/web-display-utilities',
    '/templates/flicker-defender': '/campaign-development/web-templates/web-flicker-defender',
    '/templates/template-building-checklist/flicker-defender': '/campaign-development/web-templates/web-flicker-defender',
    '/templates/templates-style-guide-and-coding-conventions': '/campaign-development/web-templates/web-templates-style-guide-and-coding-conventions',
    '/templates/template-html-classes-and-css': '/campaign-development/web-templates/web-template-html-classes-and-css',
    '/campaign-development/smart-apis': '/campaign-development/advanced-use-case-apis',
    '/campaign-development/smart-apis/smart-trends': '/campaign-development/advanced-use-case-apis/is-trends',
    '/campaign-development/smart-apis/web-surveys': '/campaign-development/advanced-use-case-apis/web-surveys'
};

const datawarehouseRedirects = {
    '/dwh': '/data-analytics',
    '/dwh/data-warehouse': '/data-analytics/data-warehouse',
    '/dwh/data-dictionary': '/data-analytics/data-dictionary',
    '/dwh/scratch-schema': '/data-analytics/scratch-schema',
};

const interfaceConfigRedirects = {
    '/config': '/interface-config',
    '/config/properties/boolean': '/interface-config/properties/boolean',
    '/config/properties/string': '/interface-config/properties/string',
    '/config/properties/number': '/interface-config/properties/number',
    '/config/properties/color': '/interface-config/properties/color',
    '/config/properties/datetime': '/interface-config/properties/datetime',
    '/config/properties/complex': '/interface-config/properties/complex',
    '/config/properties/select': '/interface-config/properties/select',
    '/config/properties/multi-select': '/interface-config/properties/multi-select',
    '/config/arrays': '/interface-config/arrays',
    '/config/decorators': '/interface-config/decorators',
    '/config/static-values': '/interface-config/static-values',
    '/config/import-export': '/interface-config/import-export',
    '/config/decorators': '/interface-config/decorators',
};

const redirectPathMap = {
    ...generalRedirects,
    ...webIntegRedirects,
    ...eventApiRedirects,
    ...mobileRedirects,
    ...campaignDevRedirects,
    ...datawarehouseRedirects,
    ...interfaceConfigRedirects,
};

module.exports = redirectPathMap;
